import { Link, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React from 'react'
import { Col, Card, Jumbotron, Container, Row, Figure } from 'react-bootstrap'
import { getCBME } from '../../../../API/getCBME'
const url = typeof window !== 'undefined' ? window.location.href : ''
function changeName() {
	String(url)
	if (url.includes('jnmc')) {
		return 'CBME Appraisal Programme'
	} else {
		return 'Academic Appraisal Program'
	}
}
const links = [
	{
		name: 'Assignment',
		to: './add/assignment',
		details: 'Add a graded submission based evaluation.',
		img: '/img/classwork/assignment.jpg',
	},
	{
		name: 'Quiz',
		to: './add/quiz',
		details: 'Add a graded question based evaluation.',
		img: '/img/classwork/quiz.jpg',
	},
	{
		name: 'Resource',
		to: './add/resource',
		details: 'Add a learning based course material.',
		img: '/img/classwork/resource.jpg',
	},
	{
		name: 'Lecture',
		to: './add/lecture',
		details: 'Add a live Video meeting lecture for all members.',
		img: '/img/classwork/lecture.jpg',
	},

	// Log Book
	// Clinical clerkship record
	// Preceptorship Diary
	// CERTIFICATES
	// ...(getCBME()
	// 	? [
	// 			{
	// 				name: 'Log Book',
	// 				to: './add/assignment?assignment_type=Logbook',
	// 				details: 'Add a graded submission based evaluation.',
	// 				img: '/img/classwork/assignment.jpg',
	// 			},
	// 			{
	// 				name: 'Clinical Clerkship Record',
	// 				to: './add/assignment?assignment_type=Clinical Clerkship Record',
	// 				details: 'Clinical clerkship record submission.',
	// 				img: '/img/classwork/assignment.jpg',
	// 			},
	// 			{
	// 				name: 'Preceptorship Diary',
	// 				to: './add/assignment?assignment_type=Preceptorship Diary',
	// 				details: 'Preceptorship Diary submission.',
	// 				img: '/img/classwork/assignment.jpg',
	// 			},
	// 			{
	// 				name: 'Certificate',
	// 				to: './add/assignment?assignment_type=Certificate',
	// 				details: 'Certificate submission.',
	// 				img: '/img/classwork/assignment.jpg',
	// 			},
	// 	  ]
	// 	: []),

	{
		name: 'External Link',
		to: './add/external',
		details: 'Add an external tool/resource material.',
		img: '/img/classwork/external.jpg',
	},
	{
		name: 'External Video Link',
		to: './add/external-video',
		details: 'Add an external Video based material.',
		img: '/img/classwork/external-video.jpg',
	},
	{
		name: 'Forum',
		to: './add/forum',
		details: 'Add a Forum.',
		img: '/img/classwork/forum.jpg',
	},
	{
		name: changeName(),
		to: './add/feedback',
		details: 'Appraisal/Feedback.',
		img: '/img/classwork/assignment.jpg',
	},
	{
		name: 'Proctor Examination',
		to: './add/proctor',
		details: 'Add a graded submission based evaluation.',
		img: '/img/classwork/quiz.jpg',
	},
	{
		name: 'Imports',
		to: './add/import',
		details: 'Add a graded submission based evaluation.',
		img: '/img/classwork/lecture.jpg',
	},
	{
		name: 'Add Notification',
		to: './add/notification',
		details: 'Add Notification that will reach all the Students and Teachers.',
		img: '/img/classwork/lecture.jpg',
	},
]
// if (url.includes('online') || url.includes('8000')) {
// 	links.push({
// 		name: 'Proctor Quiz',
// 		to: './add/proctor',
// 		details: 'Add a graded submission based evaluation.',
// 		img: '/img/classwork/quiz.jpg',
// 	})
// }
const ActivitiesList: React.FC<PageProps> = ({ params: { id } }) => {
	const { ShowDetails, type_of_course } = useGetCourseDetails(id)
	console.log(getCBME())

	return (
		<>
			{ShowDetails}
			<div className='container'>
				<div className='courses-page mx-2'>
					{/*  */}
					<h1></h1>
					<div className=' mt-4 mb-4'>
						<div className='row'>
							{(type_of_course === 'Traditional'
								? links
								: [
										{
											name: 'Module',
											to: './add/module',
											details: 'Add a module to the course.',
											img: '/img/classwork/assignment.jpg',
										},
										...links,
								  ]
							).map((elem) => (
								<>
									<Col lg={3} md={4}>
										<Link to={`${elem.to}`}>
											<Card className='classCard'>
												<Jumbotron
													className='classJumbotron px-0'
													style={{ backgroundImage: `url(${elem.img ?? '/card.jpg'})` }}
												>
													<Container className='mt-2'>
														<Row className='align-items-center '>
															<Col xs='10'></Col>

															<Col xs={2}>
																<a href='/classes' className='text-right text-white '>
																	<i className='bx bx-dots-vertical-rounded float-right'></i>
																</a>
															</Col>
														</Row>
														<Row className='align-items-center '>
															<Col>{/* <p className='text-light'>{teacher}</p> */}</Col>
														</Row>
													</Container>
													<Figure className=' float-right pr-3'>
														{/* <Figure.Image src={ProfileImage} className=' rounded-circle classImg' alt='' /> */}
													</Figure>
												</Jumbotron>
												<Card.Body>
													<Row>
														<Col>
															<h5 style={{ padding: 0, margin: 0, fontWeight: 700 }} className='text-dark'>
																{elem.name}
															</h5>
															{/* <br />
															<h6>{elem.details}</h6> */}
															{/* <div className='toDoList'>
																	<ul>
																		<li>
																			<a href='/assignment' className='card-text text-dark m-0'>
																				Assignment 2
																			</a>
																		</li>
																		<li>
																			<a href='/quiz' className='card-text text-dark m-0'>
																				Quiz 2
																			</a>
																		</li>
																	</ul>
																</div> */}
														</Col>
													</Row>
												</Card.Body>
											</Card>
										</Link>
									</Col>
								</>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ActivitiesList
